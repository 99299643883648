<template>
   <div class="container h-100">
    
    

      <div class="h-75 pt-5">
         <!-- class="d-flex justify-content-center align-items-center h-100" -->

        <div class="d-flex justify-content-end align-items-center">
          <h4 class="m-0 p-0">Already have an account?</h4>
          <router-link :to="{ name: 'Login'}"><button class="ms-2 btn btn-secondary">Log in</button></router-link>
        </div>

        <div class="d-flex justify-content-center align-items-center h-100">
         <div class="card">
            <div class="card-body" style="background-color: lightgrey; padding: 35px;">
                <i v-on:click="$router.go(-1)" class="mdi mdi-arrow-left go_back_button"></i>
               <p style="font-weight: 500; font-size: 22px;">Create your free teacher account</p>
               <div>
                <p class="line-decorated m-0 p-0">Sign up with:</p>
                <div v-on:click="this.$root.login_social" class="d-flex align-items-center my-3" style="background-color: #4285f4; padding:5px; cursor:pointer;"  data-uloginbutton="Google">
                    <div class="social_img_cards social_google" data-uloginbutton="Google"></div>
                    <p style="font-weight: 500; color:white;" class="m-0 p-0 ms-3 text-center w-75" data-uloginbutton="Google">Google</p>
                </div>

                <div v-on:click="this.$root.login_social" class="d-flex align-items-center my-3" style="background-color: #436cf2; padding:5px; cursor:pointer;"  data-uloginbutton="Clever">
                    <div class="social_img_cards social_clever" data-uloginbutton="Clever"></div>
                    <p style="font-weight: 500; color:white;" class="m-0 p-0 ms-3 text-center w-75" data-uloginbutton="Clever">Clever</p>
                </div>
                <p class="line-decorated m-0 p-0">Or Sign up with Email:</p>

                <div>
                    <form @submit.prevent="adult_signup_form">
                    <div class="form-group mb-3">
                        <label for="inputFullName">Full name</label>
                        <input type="text" class="form-control" id="inputFullName" placeholder="Enter Full name" required v-model="rldata.new_user.full_name">
                    </div>
                    <div class="form-group mb-3">
                        <label for="inputEmail">Email address</label>
                        <input type="email" class="form-control" id="inputEmail" aria-describedby="emailHelp" placeholder="Enter email" required v-model="rldata.new_user.email">
                        <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
                    </div>
                    <div class="form-group mb-3">
                        <label>Password</label>
                        <div class="input-group" id="show_hide_password">
                            <input class="form-control" type="password" required v-model="rldata.new_user.password">
                            <div class="input-group-append">
                                <span class="input-group-text"><a href=""><i class="fa fa-eye-slash" aria-hidden="true"></i></a></span>
                            </div>
                        </div>
                    </div>
                    <div class="form-check mb-3">
                        <input type="checkbox" class="form-check-input" id="termsCheck" required v-model="rldata.new_user.terms_check">
                        <label class="form-check-label" for="termsCheck">Terms of use and Privacy policy</label>
                    </div>
                    <button type="submit" class="btn btn-dark w-100">Submit</button>
                    </form>
                </div>

               </div>
            </div>
         </div>
        </div>

      </div>
   </div>
</template>

<script>
var rldata = {
  user: null,
  new_user: {
    full_name: '',
    email: '',
    password: '',
    terms_check: true
  },
}

import router from "@/router"
import axios from "axios"

export default {
  name: "Teacher_Signup",
  data () {
    return {
      rldata
    }
  },
  methods: {    
    adult_signup_form: function () {
        if (this.rldata.new_user['full_name'].length > 0 && this.rldata.new_user['email'].length > 0 && this.rldata.new_user['password'].length > 5 && this.rldata.new_user['terms_check']) {
            let data = {
              full_name: this.rldata.new_user['full_name'],
              email: this.rldata.new_user['email'],
              password: this.rldata.new_user['password'],
              gr: 'GR_TEACHER'
            };
            // console.log(data);
            this.$root.adults_server_signup(data);

        } else {
            alert('not all fields filled correctly')
        }
    },
  },
  mounted () {
    $(document).ready(function() {
        $("#show_hide_password a").on('click', function(event) {
            event.preventDefault();
            if($('#show_hide_password input').attr("type") == "text"){
                $('#show_hide_password input').attr('type', 'password');
                $('#show_hide_password i').addClass( "fa-eye-slash" );
                $('#show_hide_password i').removeClass( "fa-eye" );
            }else if($('#show_hide_password input').attr("type") == "password"){
                $('#show_hide_password input').attr('type', 'text');
                $('#show_hide_password i').removeClass( "fa-eye-slash" );
                $('#show_hide_password i').addClass( "fa-eye" );
            }
        });
    });

    // try{
    //     this.$root.getUserData(this.rldata);
    //   } catch(err){
    //     this.$root.logout();
    //   }
  },
   watch: {
     "rldata.user": function(handler){
       if(handler != null) {
        //  router.push('/dashboard')
       }
     }
   }
}
</script>

<style lang="scss" scoped>
$main-color: #ff9012;
$hover-color: #fdca6b;

.card{
  background-color: rgba(0,0,0,0.5) !important;
}

.input-group-prepend span{
  width: 40px;
  height: 100%;
  background-color: $main-color;
  color: black;
  border:0 !important;
}

input:focus{
  outline: 0 0 0 0  !important;
  box-shadow: 0 0 0 0 !important;
}

.login_btn{
  color: black;
  background-color: $main-color;
  width: 100px;
}

.login_btn:hover{
  color: black;
  background-color: $hover-color;
}


    .line-decorated {
        position: relative;
        font-size: 14px;
        z-index: 1;
        overflow: hidden;
        text-align: center;
    }
    .line-decorated:before, .line-decorated:after {
        position: absolute;
        top: 51%;
        overflow: hidden;
        width: 50%;
        height: 1px;
        content: '\a0';
        background-color: white;
    }
    .line-decorated:before {
        margin-left: -50%;
        text-align: right;
    }

  .social_img_cards {
    cursor: pointer;
    padding: 0px; 
    outline: none; 
    border: none; 
    border-radius: 0px;
    position: relative; 
    display: inherit; 
    width: 32px; 
    height: 32px; 
    left: 0px; 
    top: 0px; 
    box-sizing: content-box;
  }

  .social_google{
    background: url('/resource/img/social_logos.png') 0px -206px / 32px no-repeat;
  }

  .social_clever{
    background: url("/resource/img/social_logos.png") 0px -104px no-repeat;
  }
</style>